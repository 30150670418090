var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-box" }, [
    _c(
      "video",
      {
        ref: "video",
        staticClass: "image-box__video",
        attrs: { autoplay: "", muted: "" },
        domProps: { muted: true }
      },
      [
        _c("source", {
          attrs: { src: "/uploads/video/play.mp4", type: "video/mp4" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "video-btn",
        class: { "--on": !_vm.muted },
        on: { click: _vm.setVoice }
      },
      [
        _c("svg", { staticClass: "icon" }, [
          _c("use", { attrs: { "xlink:href": "#audio" } })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }