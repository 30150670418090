import $ from 'jquery';
import GLightbox from 'glightbox';
import Vue from 'vue';
import Swiper from 'swiper';

import 'swiper/swiper-bundle.css';
import 'glightbox/dist/css/glightbox.css';


window.GLightbox = GLightbox;
window.Swiper = Swiper;





const Components = {
	'Feedbacks':'Feedbacks.vue',
	'VideoOb':'VideoOb.vue',
}

Object.keys(Components).forEach(k => Vue.component(k, require('../vue-components/'+Components[k]).default))

window.App = new Vue({
	el: '#app',
	data:{
		requestUrl:'/app/api/',
	},
	methods:{
		scrollTo(){
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		}
	},
	mounted(){


	},
});
window.$ = $;
require('./y-map');
require('./script');