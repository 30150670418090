<template>
    <div class="video-box">
        <video autoplay muted class="image-box__video" ref="video">
            <source src="/uploads/video/play.mp4" type="video/mp4">
        </video>

        <button class="video-btn" :class="{'--on':!muted}" @click="setVoice"><svg class="icon">
            <use xlink:href="#audio"></use>
        </svg></button>
    </div>
</template>

<script>
    export default {
        name: "video-ob",
        data(){
            return{
                muted:true,
            }
        },
        methods:{
            setVoice(){
                this.muted = !this.muted;

                this.$refs.video.muted  = this.muted;
            }
        }
    }
</script>

<style scoped>

</style>