class YandexMap  {
	constructor(params = {}){
		this.params = {
			coords:[0,0],
			hintContent:'КубаньРадикал',
			balloonContent:'КубаньРадикал',
			zoom: 15,
			iconSize:[40,40],
			icon:'/uploads/images/svg/pin.svg'
		}

		this.listeners = [];
		Object.assign(this.params, params);

		if(params.on)
		{
			Object.keys(params.on).forEach(fxName => {

				this.listeners.push({
					name:fxName,
					fx:params.on[fxName]
				})
			})
		}

		this.init();

	}
	on(eventName, callback){
		this.listeners.push({
			name:eventName,
			fx:callback
		})
	}
	init(){
		this.isLoaded = false;
		this.$el = document.querySelector(this.params.el) || false;

		if(this.$el) {
			this.initJS()
			let domElementData = JSON.parse(this.$el.getAttribute('data')) || {};
			Object.assign(this.params, domElementData);
		};
	}
	refresh(){
		this.init();
	}
	createScriptElement(){
		let el = document.createElement('script');
		el.id = "yandex_maps_script";
		el.type = "text/javascript";
		el.src = "//api-maps.yandex.ru/2.1/?lang=ru_RU";
		return el
	}
	bindEvents(){
		if(window['ymaps'] !== undefined){
			document.addEventListener('scroll', e => {
				if(!this.isLoaded && this.isVisibleElement()){
					this.createMap()
				}
			})

			setTimeout(() => document.dispatchEvent(new CustomEvent('scroll')), 500)

		}else{

			setTimeout(() => this.bindEvents(), 500);
		}
	}
	initJS(){
		let yms = document.querySelector('#yandex_maps_script');
		let head = document.querySelector('head');
		if(!yms)
		{
			yms = this.createScriptElement();
			head.appendChild(yms);
		}

		this.$el.classList.add('--is-loading')
		this.bindEvents();
	}

	createMap(){
		this.$el.classList.remove('--is-loading')
		this.map = new ymaps.Map(this.$el, {
			center: this.params.coords,
			zoom: this.params.zoom,
			controls: []
		}, {
			searchControlProvider: 'yandex#search'
		});

		this.map.controls.add('zoomControl', {
			size: "small",
			position:{
				top:'1em',
				right:'1em',
			}
		});


		this.map.behaviors.disable(['scrollZoom']);
		this.isLoaded = true;

		this.dispathEvent('init');
	}
	dispathEvent(en){
		if(!this.listeners) return false;
		let ev = this.listeners.filter(e => e.name == en);
		if(ev.length> 0) ev.forEach(e => {
			e.fx.call(this, this)
		})
	}
	isVisibleElement(){
		const scroll = window.scrollY || window.pageYOffset
		const boundsTop = this.$el.getBoundingClientRect().top + scroll

		const viewport = {
			top: scroll,
			bottom: scroll + window.innerHeight
		}

		const bounds = {
			top: boundsTop,
			bottom: boundsTop + this.$el.clientHeight
		}

		return (
			( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom ) ||
			( bounds.top <= viewport.bottom && bounds.top >= viewport.top )
		);
	}
}


document.addEventListener('DOMContentLoaded', () => {

	new YandexMap({
		el:".map",
		coords:[45.006661194311,38.971384584685],
		on:{
			init(){
				this.map.geoObjects.add(
					new ymaps.Placemark(
						this.map.getCenter(),
						{
							hintContent: this.params.hintContent,
							balloonContent: this.params.balloonContent
						},
						{
							iconLayout: 'default#image',
							iconImageHref: this.params.icon,
							iconImageSize: this.params.iconSize,
							iconImageOffset: [-this.params.iconSize[0]/2, -this.params.iconSize[1]]
						}
					)
				);

			}
		}
	});



})