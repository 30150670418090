var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "swiper-container slider" }, [
    _c("div", { staticClass: "swiper-wrapper " }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "slider__contolls" }, [
      false
        ? _c("div", { staticClass: "slider__pagen swiper-pagination" }, [
            _vm._v(_vm._s(_vm.currentSliders))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "slider__btn swiper-button-prev swiper-button-white",
        on: { click: _vm.slidePrev }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "slider__btn swiper-button-next swiper-button-white",
        on: { click: _vm.slideNext }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }