<template>
	<div class="swiper-container slider">
		<div class="swiper-wrapper ">
			<slot></slot>
		</div>
		<div class="slider__contolls">
			<div class="slider__pagen swiper-pagination" v-if="false">{{currentSliders}}</div>
			<div class="slider__btn swiper-button-prev swiper-button-white" @click="slidePrev"></div>
			<div class="slider__btn swiper-button-next swiper-button-white" @click="slideNext"></div>
		</div>

	</div>
</template>
<script>

	export default {
		name: "main-slider",
		data(){

			return {
				el:'.slider',
				Swiper:null,
				slides:[],
				currentSliders:'',
				state:{
					isLoaded:false,
				}
			}
		},
		computed:{

		},
		mounted(){

			let params = new URLSearchParams();
			params.append('component', 'comments');
			params.append('action', 'getActive');

			this.state.isLoading = true;

			this.createSlider();




		},
		methods:{
			slidePrev(){
				this.Swiper.slidePrev();
			},
			slideNext(){
				this.Swiper.slideNext();
			},
			shortName(name){

				let nameD = name.split(' ');
				let newNAme = '';

					nameD.forEach((name, k) => {

					if(k == 0)
					{
						newNAme += name + ' ';
					}else
					{
						newNAme += name[0]+ '. ' ;
					}
				})



				return newNAme;
			},
			date(date){
				let today = new Date(date);
				return today.toLocaleString('ru', {
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				})
			},
			createSlider(){

				if(!this.slides.length && !document.querySelector(this.el))
				{
					setTimeout(() => this.createSlider(), 500);
				}
				else{

					this.state.isLoaded = true;

					setTimeout(() => {
						this.Swiper = new Swiper(this.el, {
							loop: true,
							spaceBetween:30,
							fadeEffect: {
								crossFade: true
							},
							pagination: {
								el: this.el+' .swiper-pagination',
							},
							navigation: {
								nextEl: this.el+' .swiper-button-next',
								prevEl: this.el+' .swiper-button-prev',
							},

						});

			

						this.currentSliders = `${this.Swiper.realIndex+1} из ${this.slides.length}`;
						this.Swiper.on('slideChange', () => {
							this.currentSliders = `${this.Swiper.realIndex+1} из ${this.slides.length}`;
						});

					},500)
				}

			}
		}
	}
</script>